import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa'; // Removed FaPhone as it's not used
import logoImage from '../assets/whatsapp.png';

const FloatingContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1100;
`;

const IconContainer = styled.div`
  font-size: 70px;  // Increased from 50px to 70px
  cursor: pointer;
  color: green;  // Set to green
  transition: transform 0.3s ease;
  margin-right: 20px;

  &:hover {
    transform: scale(1.1);
    color: darkgreen;  // A darker green on hover for better contrast
  }
`;

const ChatWindow = styled.div`
  width: 300px;
  height: 400px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const Message = styled.div`
  padding: 5px 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border-radius: 10px;
  align-self: ${props => props.isSender ? 'flex-end' : 'flex-start'};
  font-style: ${props => props.isFancy ? 'italic' : 'normal'};
`;

const InputArea = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
`;

const Logo = styled.img`
  height: 150px;
  width: auto;
  margin-left: 2%; // Adjust based on visual alignment with the background

  @media (max-width: 768px) {
    height: 100px; // Adjust height for smaller screens
  }
`;

const ChatView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);  // State to handle messages

  const toggleChat = () => {
    if (!isOpen && messages.length === 0) {
      // If chat is being opened for the first time, add the template message
      setMessages([
        ...messages,
        { text: 'Merhaba GM Elit e hoşgeldiniz, bakıcı hizmetlerimiz hakkında fazlasını öğrenmek için bizimle iletişime geçin.', isSender: false, isFancy: true }
      ]);
    }
    setIsOpen(!isOpen);
  };

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      const newMessages = [...messages, { text: inputValue, isSender: true, isFancy: false }];
      setMessages(newMessages);
      setInputValue('');
    }
  };

  return (
    <FloatingContainer>
      {isOpen ? (
        <IconContainer onClick={toggleChat}>
          <FaTimesCircle /> {/* Close icon when chat is open */}
        </IconContainer>
      ) : (
        <IconContainer onClick={toggleChat}>
          <Logo src={logoImage} alt="Company Logo" />
        </IconContainer>
      )}
      {isOpen && (
        <ChatWindow>
          <MessagesContainer>
            {messages.map((msg, index) => (
              <Message key={index} isSender={msg.isSender} isFancy={msg.isFancy}>
                {msg.text}
              </Message>
            ))}
          </MessagesContainer>
          <InputArea 
            placeholder="Mesajınızı yazın..." 
            value={inputValue} 
            onChange={handleInputChange}
            onKeyPress={handleSendMessage}
          />
        </ChatWindow>
      )}
    </FloatingContainer>
  );
};

export default ChatView;
