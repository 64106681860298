import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';  // Using HttpBackend instead of XHR backend
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)  // To load translations over a server
  .use(LanguageDetector)  // Automatically detect language settings
  .use(initReactI18next)  // Passes i18n down to react-i18next
  .init({
    fallbackLng: 'tr',  // Fallback language is Turkish
    lng: 'tr',  // Default language is Turkish
    debug: true,  // Enable debug mode to see logs in the console

    interpolation: {
      escapeValue: false,  // React already safes from XSS
    },

    // Configuration for the backend
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // Path to the translations files
    }
  });

export default i18n;
