import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import certificationImage from '../assets/iskur.png'; // Replace with the correct path to your image

const FooterContainer = styled.footer`
  padding: 20px 15%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Original setting for larger screens
  gap: 40px;
  font-size: 20px;
  color: #333;
  border-top: 2px solid #ccc;
  align-items: start;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Single column for mobile view
    padding: 20px 5%; // Reduced padding for smaller screens
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 25px;
  color: #4a54e1;
  margin-bottom: 15px;
  font-weight: bold;
`;

const ContactButton = styled.button`
  background-color: #4a54e1;
  color: white;
  border: none;
  padding: 12px 24px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;

  &:hover {
    background-color: #393fd1;
  }
`;

const Text = styled.p`
  margin-bottom: 10px;
  line-height: 1.2;
  color: #666;
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 30px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageSection = styled.div`
  text-align: center;
  img {
    width: 150px;
    height: auto;
    border-radius: 10px;
  }
`;

const LanguageSelector = styled.select`
  padding: 8px 15px;
  cursor: pointer;
  background: #6495ED;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  
  &:hover {
    background: #4682B4;
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FooterContainer>
      <FooterSection>
        <Title>{t('footerContactTitle')}</Title>
        <Text>{t('footerContactText')}</Text>
        <ContactButton>{t('footerMailButton')}</ContactButton>
      </FooterSection>
      <ImageSection>
        <img src={certificationImage} alt="Certification" />
        <Text>{t('footerCertificationText')}</Text>
        <Text>{t('footerCertificationDate')}</Text>
        <Text>{t('footerLawNotice')}</Text>
      </ImageSection>
      <ContactInfo>            
        <LanguageSelector onChange={handleLanguageChange}>
          <option value="tr">Türkçe</option>
          <option value="en">English</option>
          <option value="fil">Filipino</option>
        </LanguageSelector>
        <Title>{t('footerAddressTitle')}</Title>
        <Text>{t('footerAddress')}</Text>
        <Text>{t('footerPhone')}</Text>
        <SocialMedia>
          <FaTwitter />
          <FaLinkedin />
          <FaInstagram />
          <FaFacebook />
        </SocialMedia>
      </ContactInfo>
    </FooterContainer>
  );
};

export default Footer;
