import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ContactContainer = styled.div`
  padding: 50px 10%; // Adjusted padding for better responsiveness
  display: flex;
  flex-direction: column;
  align-items: center; // Centers the content vertically and horizontally
  text-align: center;
`;

const Title = styled.h2`
  color: #333;
  font-size: 28px;
  margin-bottom: 30px; // Provides more space below the title for clarity
`;

const Form = styled.form`
  width: 100%; // Ensures the form uses the maximum width
  max-width: 500px; // Slightly reduced for better form control visibility
  display: flex;
  flex-direction: column;
  gap: 15px; // Manages vertical spacing between form elements
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px; // Increases font size for better readability
`;

const Textarea = styled.textarea`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 120px; // Adjusted for balanced aesthetic in form
  font-size: 16px; // Consistent font size with inputs
`;

const Button = styled.button`
  padding: 15px 0; // More padding for a better click area
  background-color: #4a54e1;
  color: white;
  font-size: 18px; // Larger font size for more emphasis
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; // Smooth transition for hover effect

  &:hover {
    background-color: #393fd1;
  }
`;

const ContactSection = () => {
  const { t } = useTranslation();

  return (
    <ContactContainer id="contact">
      <Title>{t('contactTitle')}</Title>
      <Form>
        <Input type="text" placeholder={t('yourName')} />
        <Input type="email" placeholder={t('yourEmail')} />
        <Input type="tel" placeholder={t('yourPhoneNumber')} />
        <Textarea placeholder={t('yourMessage')}></Textarea>
        <Button type="submit">{t('send')}</Button>
      </Form>
    </ContactContainer>
  );
};

export default ContactSection;
