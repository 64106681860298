import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import caregiverImage from '../assets/section3.png'; // Adjust the image path accordingly

const SectionContainer = styled.div`
  background-color: #fff; // Consistent white background for continuity
  padding: 50px 5%; // Adjusted padding for all screen sizes
  display: flex;
  flex-direction: column; // Stack vertically on smaller screens
  align-items: center; // Ensures vertical alignment
  justify-content: center; // Centering content on smaller screens
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row; // Original layout for larger screens
    justify-content: space-between; // Proper spacing between elements
    padding: 50px 15%; // Original padding for larger screens
    gap: 30px;
  }
`;

const TextContainer = styled.div`
  width: 100%; // Full width on smaller screens

  @media (min-width: 768px) {
    width: 60%; // Text occupies 60% of the container on larger screens
  }
`;

const Title = styled.h2`
  font-size: 24px; // Slightly reduced for mobile
  margin-bottom: 20px; // Spacing under the title
`;

const Text = styled.p`
  font-size: 16px; // Smaller font size for mobile
  line-height: 1.6; // Line height for comfortable reading
`;

const ImageContainer = styled.div`
  width: 100%; // Full width on smaller screens

  @media (min-width: 768px) {
    width: 40%; // Image occupies 40% of the container on larger screens
  }
`;

const CaregiverImage = styled.img`
  width: 100%; // Image fills its container
  height: auto; // Maintain aspect ratio
  border-radius: 10px; // Soft rounded corners as in the previous sections
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Light shadow for depth
`;

const PermittedCaregiverSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('permittedCaregiverTitle')}</title>
        <meta name="description" content={t('permittedCaregiverDescription')} />
        <meta name="keywords" content={t('permittedCaregiverKeywords')} />
        <link rel="canonical" href="http://www.gmelit.co/permitted-caregiver" />
      </Helmet>
      <SectionContainer>
        <TextContainer>
          <Title>{t('permittedCaregiverSectionTitle')}</Title>
          <Text>{t('permittedCaregiverText1')}</Text>
          <Text>{t('permittedCaregiverText2')}</Text>
          <Text>{t('permittedCaregiverText3')}</Text>
          <Text>{t('permittedCaregiverText4')}</Text>
        </TextContainer>
        <ImageContainer>
          <CaregiverImage src={caregiverImage} alt={t('permittedCaregiverSectionTitle')} />
        </ImageContainer>
      </SectionContainer>
    </>
  );
};

export default PermittedCaregiverSection;
