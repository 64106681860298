import React from 'react';

function JoinUs() {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Join Us</h1>
      <p>Be a part of our community and make a difference!</p>
      <form>
        <label>
          Name:
          <input type="text" name="name" />
        </label>
        <label>
          Email:
          <input type="email" name="email" />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default JoinUs;
