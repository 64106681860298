import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import caregiverImage from '../assets/section2.png'; // Make sure the path to the image is correct

const SectionContainer = styled.div`
  background-color: #fff; // Maintaining a consistent white background
  padding: 20px 5%; // Adjusted padding for better mobile responsiveness
  display: flex;
  flex-direction: column; // Stack vertically on all screens
  align-items: center; // Centers content vertically

  @media (min-width: 768px) {
    flex-direction: row; // Side-by-side on larger screens
    justify-content: space-between; // Restore horizontal spacing
    align-items: center; // Vertically align content
    padding: 50px 15%; // Original padding for larger screens
    gap: 30px; // Maintain original gap on larger screens
  }
`;

const TextContainer = styled.div`
  width: 100%; // Full width on all screens

  @media (min-width: 768px) {
    width: 60%; // Text takes up 60% of the container on larger screens
    text-align: left; // Align text to the left on larger screens
  }
`;

const Title = styled.h2`
  font-size: 24px; // Adjusted font size for better visibility on mobile
  margin-bottom: 15px; // Space below the title
`;

const Text = styled.p`
  font-size: 16px; // Smaller font size for better fit on mobile
  line-height: 1.5; // Adjusted line spacing for readability
`;

const ImageContainer = styled.div`
  width: 100%; // Full width on smaller screens

  @media (min-width: 768px) {
    width: 40%; // Image takes up 40% of the container on larger screens
  }
`;

const CaregiverImage = styled.img`
  width: 100%; // Full width of the container
  border-radius: 10px; // Rounded corners as in the design
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
`;

const InternationalCaregiverSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('caregiverSectionTitle')}</title>
        <meta name="description" content={t('caregiverSectionDescription')} />
        <meta name="keywords" content={t('caregiverSectionKeywords')} />
        <link rel="canonical" href="http://www.gmelit.co/international-caregiver" />
      </Helmet>
      <SectionContainer id="mission">
        <TextContainer>
          <Title>{t('caregiverSectionTitle')}</Title>
          <Text>{t('caregiverSectionText1')}</Text>
          <Text>{t('caregiverSectionText2')}</Text>
          <Text>{t('caregiverSectionText3')}</Text>
        </TextContainer>
        <ImageContainer>
          <CaregiverImage src={caregiverImage} alt={t('caregiverSectionTitle')} />
        </ImageContainer>
      </SectionContainer>
    </>
  );
};

export default InternationalCaregiverSection;
