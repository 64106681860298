import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import caregiverImage from '../assets/home-banner.png';  // Replace with actual image path

const ServicesContainer = styled.div`
  background-color: #fff; // White background
  padding: 50px 15%; // Consistent padding with other sections
  display: flex;
  flex-direction: column; // Stacks children vertically on smaller screens
  align-items: center; // Centers items vertically
  justify-content: center; // Center alignment for the items

  @media (min-width: 768px) {
    flex-direction: row; // Original row layout for larger screens
    justify-content: space-between; // Space between text and image
    align-items: center; // Aligns items vertically
  }
  gap: 30px; // Gap between components for better layout
`;

const TextContainer = styled.div`
  width: 100%; // Full width on smaller screens

  @media (min-width: 768px) {
    width: 60%; // Text content takes up 60% of the container on larger screens
  }
`;

const Title = styled.h2`
  font-size: 28px; // Large font size for visibility
  margin-bottom: 20px; // Space below the title
`;

const Text = styled.p`
  font-size: 18px; // Readable text size
  line-height: 1.6; // Adequate line spacing for readability
`;

const ImageContainer = styled.div`
  width: 100%; // Full width on smaller screens

  @media (min-width: 768px) {
    width: 40%; // Image takes up 40% of the container on larger screens
  }
`;

const CaregiverImage = styled.img`
  width: 100%; // Full width of the container
  height: auto; // Maintain aspect ratio
  border-radius: 10px; // Rounded corners as in the image
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
`;

const ServicesSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('servicesTitle')}</title>
        <meta name="description" content={t('servicesDescription')} />
        <meta name="keywords" content={t('servicesKeywords')} />
        <link rel="canonical" href="http://www.gmelit.co/filipino-caregiver-services" />
      </Helmet>
      <ServicesContainer id="services">
        <TextContainer>
          <Title>{t('servicesSectionTitle')}</Title>
          <Text>{t('servicesText1')}</Text>
          <Text>{t('servicesText2')}</Text>
          <Text>{t('servicesText3')}</Text>
        </TextContainer>
        <ImageContainer>
          <CaregiverImage src={caregiverImage} alt={t('servicesSectionTitle')} />
        </ImageContainer>
      </ServicesContainer>
    </>
  );
};

export default ServicesSection;
