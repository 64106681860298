import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import backgroundImage from '../assets/original-banner.jpg';
import backgroundMobileImage from '../assets/mobile-banner.png';
import logoImage from '../assets/logo.png';
import StyledButton from './StyledButton';
import { useTranslation } from 'react-i18next';

const MainContainer = styled.div`
  height: 100vh;
  background-image: url(${backgroundImage}); // Default to the desktop background
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    background-image: url(${backgroundMobileImage}); // Use mobile background on smaller screens
  }
`;

const Nav = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
`;

const Logo = styled.img`
  height: 72px;
  width: auto;
  margin-left: 2%; // Adjust based on visual alignment with the background

  @media (max-width: 768px) {
    margin-left: 0; // Remove left margin for smaller screens
    margin-bottom: 10px; // Add space between logo and nav items
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  font-size: 24px;
  padding: 5px 10px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
  transition: visibility 0.3s;

  &.NavHidden {
    visibility: hidden;
  }

  &.NavVisible {
    visibility: visible;
  }

  &:hover {
    color: #555;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 2%; // Adjust this margin to align the navigation items appropriately
  transition: visibility 0.3s;

  &.NavHidden {
    visibility: hidden;
  }

  &.NavVisible {
    visibility: visible;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

const NavLink2 = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 24px;
  padding: 5px 10px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.25);

  &:hover {
    color: #555;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
  }
`;

const LanguageSelector = styled.select`
  padding: 8px 15px;
  cursor: pointer;
  background: #6495ED;
  color: white;
  border: none; // No border
  border-radius: 20px; // Fully rounded corners
  font-size: 18px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);

  &:hover {
    background: #4682B4;
  }

  @media (max-width: 768px) {
    margin-top: 10px; // Add top margin for visual separation on mobile
  }
`;

const Header = () => {
  const { t, i18n } = useTranslation();
  const [navVisibility, setNavVisibility] = useState('NavVisible');

  const handleScroll = () => {
    console.log('Scrolling');
    setNavVisibility('NavHidden');
  };

  const handleMouseOver = () => {
    setNavVisibility('NavVisible');
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setNavVisibility('NavVisible');
    } else {
      setNavVisibility('NavHidden');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <MainContainer onMouseOver={handleMouseOver}>
        <Nav>
          <Logo src={logoImage} alt="Company Logo" />
          <NavItems className={navVisibility}>
            <NavLink href="#about">{t('about')}</NavLink>
            <NavLink href="#mission">{t('mission')}</NavLink>
            <NavLink href="#services">{t('services')}</NavLink>
            <StyledButton>
              <NavLink2 href="#contact">{t('contact')}</NavLink2>
            </StyledButton>
            <LanguageSelector onChange={(event) => i18n.changeLanguage(event.target.value)}>
              <option value="tr">Türkçe</option>
              <option value="en">English</option>
              <option value="fil">Filipino</option>
            </LanguageSelector>
          </NavItems>
        </Nav>
      </MainContainer>
    </>
  );
};

export default Header;
