import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #4B3F6B; // Dark purple background
  color: white; // White text
  font-size: 18px;
  padding: 10px 20px; // Top/bottom padding of 10px and left/right of 20px
  border: none; // No border
  border-radius: 20px; // Fully rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow for 3D effect
  cursor: pointer; // Cursor indicates it's clickable
  transition: background-color 0.3s, box-shadow 0.3s; // Smooth transitions for hover effects

  &:hover {
    background-color: #5A4E7C; // Slightly lighter purple on hover
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); // More pronounced shadow on hover
  }

  &:focus {
    outline: none; // Removes the default focus outline
    box-shadow: 0 0 0 2px #FFFFFF, 0 0 0 4px #4B3F6B; // Custom focus styles
  }
`;

export default StyledButton;
