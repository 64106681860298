import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

// Styled components
const AboutContainer = styled(motion.div)`
  color: #333;
  padding: 100px 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const Title = styled(motion.h1)`
  color: #000;
  font-size: 36px;
  margin-bottom: 20px;
`;

const Text = styled(motion.p)`
  font-size: 18px;
  line-height: 1.6;
  margin: 0 0 20px;
  max-width: 600px;
`;

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.3, duration: 0.6 } }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.6 } }
};

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <AboutContainer
      id="about"
      initial="hidden"
      animate="visible"
      variants={containerVariants}  // Ensure variants are defined or imported
    >
      <Title variants={itemVariants}>{t('aboutTitle')}</Title>
      <Text variants={itemVariants}>{t('aboutText1')}</Text>
      <Text variants={itemVariants}>{t('aboutText2')}</Text>
      <Text variants={itemVariants}>{t('aboutText3')}</Text>
      <Text variants={itemVariants}>{t('aboutText4')}</Text>
    </AboutContainer>
  );
};

export default AboutSection;
