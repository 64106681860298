import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const BlogContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 80%;
  max-width: 600px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const Content = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
`;

const Button = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState(0);

  // Simulating fetching JSON data
  useEffect(() => {
    // Assuming blogData.json is in the public directory
    fetch('/blogData.json')
      .then(response => response.json())
      .then(data => setBlogs(data.blogs))
      .catch(error => console.error('Error fetching blog data:', error));
  }, []);

  const handleNext = () => {
    setCurrentBlog((prevCurrentBlog) => (prevCurrentBlog + 1) % blogs.length);
  };

  return (
    <Container>
      {blogs.length > 0 ? (
        <BlogContainer>
          <Title>{blogs[currentBlog].title}</Title>
          <Content>{blogs[currentBlog].content}</Content>
          <Button onClick={handleNext}>Next Blog</Button>
        </BlogContainer>
      ) : (
        <Content>Loading blogs...</Content>
      )}
    </Container>
  );
}

export default Blog;
