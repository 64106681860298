import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';
import ChatView from './components/ChatView';
import AboutSection from './components/AboutSection';
import ServicesSection from './components/ServicesSection';
import InternationalCaregiverSection from './components/InternationalCaregiverSection';
import PermittedCaregiverSection from './components/PermittedCaregiverSection';
import ContactSection from './components/ContactContainer';
import JoinUs from './components/JoinUs';
import './components/i18n';
import Blog from './components/Blog';
import { Analytics } from '@vercel/analytics/react';

const SectionDivider = styled.div`
  height: 2px;
  width: 80%;
  margin: 50px auto;
  background-color: #ccc;
`;

const StyledContainer = styled.div`
  width: 100%; // Ensure the container uses full width
  max-width: 100vw; // Prevents horizontal scrolling by not allowing the container to exceed the viewport width
  overflow-x: hidden; // Hide horizontal overflow
`;

console.log(Analytics);

function App() {
  return (
    <Router>
      <div>
        <Analytics />
        <Routes>
          <Route path="/" element={
            <StyledContainer>
              <Header />
              <ChatView />
              <AboutSection />
              <SectionDivider />
              <ServicesSection />
              <SectionDivider />
              <InternationalCaregiverSection />
              <SectionDivider />
              <PermittedCaregiverSection />
              <SectionDivider />
              <ContactSection />
              <Footer />
            </StyledContainer>
          } />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
